<template>
  <div class="form_box">
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="120px" class="demo-ruleForm">
      <el-row :span="24">
        <el-col :span="12">
          <el-form-item label="名字" prop="trueName">
            <el-input v-model="ruleForm.trueName" :maxlength="15" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="手机号" prop="phoneNumber">
            <el-input v-model='ruleForm.phoneNumber' :disabled="isDisabled" :maxlength="11" @input="handlePhoneInput" />
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item label="身份证号">
        <el-input v-model="ruleForm.idNumber" :disabled="isIDDisabled" @blur="getAgeAandSex"
          @input="handleIdCardInput" />
      </el-form-item>
      <el-row :span="24">
        <el-col :span="12">
          <el-form-item label="年龄" prop="age">
            <el-input v-model="ruleForm.age" :disabled="sexDisabled" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="性别" prop="sex">
            <el-select v-model="ruleForm.sex" :disabled="sexDisabled">
              <el-option :label="item.label" :value="item.value" :key="item.value" v-for="item in sex" />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>

      <el-form-item label="身高/体重/腰围">
        <div class="height_bxo">
          <div>
            <el-form-item prop="height" style="margin-bottom: 0">
              <el-input v-model="ruleForm.height" :maxlength="6" @input="event => handleInput(event, 'height')" />
            </el-form-item>
            <span>CM</span>
          </div>
          <div>
            <el-form-item prop="weight" style="margin-bottom: 0">
              <el-input v-model="ruleForm.weight" :maxlength="6" @input="event => handleInput(event, 'weight')" />
            </el-form-item>
            <span>KG</span>
          </div>
          <div>
            <el-form-item prop="waistline" style="margin-bottom: 0">
              <el-input v-model="ruleForm.waistline" :maxlength="6" @input="event => handleInput(event, 'waistline')" />
            </el-form-item>
            <span>CM</span>
          </div>
        </div>
      </el-form-item>
      <el-form-item label="血压/血糖">
        <div class="height_bxo">
          <div>
            <el-form-item prop="bloodPressure" style="margin-bottom:0;width: 100%">
              <el-input v-model="ruleForm.bloodPressure" :maxlength="4"
                @input="event => handleInput(event, 'bloodPressure')" />
            </el-form-item>
            <span>mmHg</span>
          </div>
          <div>
            <el-form-item prop="bloodSugar" style="margin-bottom: 0;width: 100%">
              <el-input v-model="ruleForm.bloodSugar" :maxlength="4"
                @input="event => handleInput(event, 'bloodSugar')" />
            </el-form-item>
            <span>mmol</span>
          </div>
        </div>
      </el-form-item>
      <el-form-item label="血脂(mmol/L)">
        <div class="height_bxo">
          <div>
            <span>总胆固醇</span>
            <el-form-item prop="bloodFat.cholesterol">
              <el-input v-model="ruleForm.bloodFat.cholesterol" :maxlength="4"
                @input="event => handleInput(event, 'bloodFat.cholesterol')" />
            </el-form-item>
          </div>
          <div>
            <span>高密度脂蛋白</span>
            <el-form-item prop="bloodFat.highDensityLipoprotein">
              <el-input v-model="ruleForm.bloodFat.highDensityLipoprotein" :maxlength="4"
                @input="event => handleInput(event, 'bloodFat.highDensityLipoprotein')" />
            </el-form-item>
          </div>
        </div>
        <div class="height_bxo">
          <div>
            <span>低密度脂蛋白</span>
            <el-form-item prop="bloodFat.lowhDensityLipoprotein" style="margin-bottom: 0">
              <el-input v-model="ruleForm.bloodFat.lowhDensityLipoprotein" :maxlength="4"
                @input="event => handleInput(event, 'bloodFat.lowhDensityLipoprotein')" />
            </el-form-item>
          </div>
          <div>
            <span>甘油三酯</span>
            <el-form-item prop="bloodFat.triglyceride" style="margin-bottom: 0">
              <el-input v-model="ruleForm.bloodFat.triglyceride" :maxlength="4"
                @input="event => handleInput(event, 'bloodFat.triglyceride')" />
            </el-form-item>
          </div>
        </div>
      </el-form-item>
      <el-row :span="24">
        <el-col :span="12">
          <el-form-item label="人群属性" prop="healthConditionId">
            <el-select v-model="ruleForm.healthConditionId">
              <el-option :label="item.label" :value="item.value" v-for="item in healthCondition" :key="item.value" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="IDC">
            <el-input v-model="ruleForm.idc" :maxlength="20" />
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item label="病种" prop="diseaseIds" v-if="ruleForm.healthConditionId == 8">
        <el-select multiple filterable allow-create @focus="isHasDisease" default-first-option v-model="diseaseIds">
          <el-option :label='item.diseaseName' :value='item.diseaseId' v-for="item in diseases" :key="item.diseaseId" />
        </el-select>
      </el-form-item>
      <el-form-item label="其它病种" prop="otherDisease" v-if="ruleForm.healthConditionId == 0">
        <el-input v-model="ruleForm.otherDisease" :maxlength="50" />
      </el-form-item>
      <div style="display: flex" v-if="this.operateBox.operate === 'add'">
        <el-form-item label="社区选择" prop="regionCode" style="flex: 2">
          <el-select v-model="ruleForm.regionCode" clearable @change="getAreaCode" filterable>
            <el-option v-for="item in options" :key="item.regionCode" :value="item.regionCode" :label="item.area" />
          </el-select>
        </el-form-item>
        <el-form-item prop="communityId" style="flex: 1;margin-left: 10px" label-width="0">
          <el-select v-model="ruleForm.communityId" filterable>
            <el-option :label="item.name" :value="item.id" v-for="item in communityList" :key="item.id" />
          </el-select>
        </el-form-item>
      </div>
      <el-form-item>
        <el-button type="primary" @click="submitForm('ruleForm')">保存</el-button>
        <el-button @click="cancelFrom">取消</el-button>
      </el-form-item>
    </el-form>

  </div>
</template>

<script>
import api from '@/api/index'
import { healthConditionData } from '@/utils/baseData'
import { getIdCard, isCardID } from '@/utils/IdCrad'
import debounce from 'lodash/debounce';
export default {
  props: {
    operateBox: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      ruleForm: {
        idc: '',
        trueName: '',
        age: null,
        sex: null,
        idNumber: '',
        phoneNumber: '',
        diseaseId: '',
        communityId: '',
        healthConditionId: '',
        height: '',
        weight: '',
        otherDisease: '',
        waistline: '',
        bloodPressure: '',
        bloodSugar: '',
        bloodFat: {
          cholesterol: '',
          highDensityLipoprotein: '',
          lowhDensityLipoprotein: '',
          triglyceride: ''
        }
      },
      isDisabled: false,
      isIDDisabled: false,
      sexDisabled: false,
      communityList: [],
      rules: {
        trueName: [
          { required: true, message: '不能为空', trigger: 'change' },
        ],
        age: [
          { required: true, message: '不能为空', trigger: 'change' },
        ],
        sex: [
          { required: true, message: '不能为空', trigger: 'change' },
        ],
        phoneNumber: [
          { required: true, message: '不能为空', trigger: 'change' },
          { pattern: /^(1[3-9]\d{9})$/, message: '请输入有效的手机号', trigger: 'change' }
        ],
        communityId: [
          { required: true, message: '不能为空', trigger: 'change' },
        ],
        healthConditionId: [
          { required: true, message: '不能为空', trigger: 'change' },
        ],
        height: [
          { required: true, message: '不能为空', trigger: 'change' },
          {
            validator: (rule, value, callback) => {
              const height = parseFloat(value);
              if (!isNaN(height) && height >= 50 && height <= 300) {
                callback();
              } else {
                callback(new Error("范围应在50cm到300cm之间"));
              }
            },
            trigger: "change"
          }
        ],
        weight: [
          { required: true, message: '不能为空', trigger: 'change' },
          {
            validator: (rule, value, callback) => {
              const weight = parseFloat(value);
              if (!isNaN(weight) && weight >= 10 && weight <= 500) {
                callback();
              } else {
                callback(new Error("范围应在10kg到500kg之间"));
              }
            },
            trigger: "change"
          }
        ],
        waistline: [{ validator: this.validateInputRange, trigger: 'change' }],
        bloodPressure: [{ validator: this.validateInputRange, trigger: 'change' }],
        bloodSugar: [{ validator: this.validateInputRange, trigger: 'change' }],
        "bloodFat.cholesterol": [{ validator: this.validateInputRange, trigger: 'change' }],
        "bloodFat.highDensityLipoprotein": [{ validator: this.validateInputRange, trigger: 'change' }],
        "bloodFat.lowhDensityLipoprotein": [{ validator: this.validateInputRange, trigger: 'change' }],
        "bloodFat.triglyceride": [{ validator: this.validateInputRange, trigger: 'change' }],
      },
      options: [],
      sex: [
        { value: 1, label: '男' },
        { value: 0, label: '女' }
      ],
      diseases: [],
      healthCondition: healthConditionData,
      diseaseIds: null,//病种,
      idNumber: null
    };
  },
  created() {
    if (this.operateBox.id != null) {
      this.fetchResidentInfo(this.operateBox.id)
      this.isDisabled = true
    }
    this.options = this.$store.state.userArea
  },
  methods: {
    validateInputRange(rule, value, callback) {
      if (value === '') {
        // 如果值为空，则校验通过
        callback();
      } else {
        const ranges = {
          waistline: { min: 60, max: 100, message: '腰围值必须在60到100之间' },
          bloodPressure: { min: 0, max: 300, message: '血压值必须在0到300之间' },
          bloodSugar: { min: 0, max: 27.8, message: '血糖值必须在0到27.8之间' },
          'bloodFat.cholesterol': { min: 0.78, max: 9.05, message: '总胆固醇值必须在0.78到9.05之间' },
          'bloodFat.highDensityLipoprotein': { min: 0.52, max: 2.59, message: '取值范围在0.52到2.59之间' },
          'bloodFat.lowhDensityLipoprotein': { min: 1.04, max: 6.48, message: '取值范围在1.04到6.48之间' },
          'bloodFat.triglyceride': { min: 0.34, max: 5.64, message: '甘油三酯必须在0.34到5.64之间' },
        };

        const range = ranges[rule.field];

        const numericValue = parseFloat(value);
        if (!isNaN(numericValue) && numericValue >= range.min && numericValue <= range.max) {
          callback();
        } else {
          callback(new Error(range.message));
        }
      }
    },
    /** 表单限制输入 */
    //只允许输入数字
    handlePhoneInput(event) {
      this.ruleForm.phoneNumber = event.replace(/\D/g, '');
    },
    handleIdCardInput(event) {
      this.ruleForm.idNumber = event.replace(/[^a-zA-Z0-9]/g, '');
    },
    handleInput(event, field) {
      // 过滤用户输入，只保留数字和小数点，并限制小数点后最多只能有两位有效数字
      const filteredValue = event.replace(/[^\d.]/g, '').replace(/^(\d*\.?\d{0,2}).*/, '$1');

      // 使用动态属性名将过滤后的值赋给指定的字段
      this.$set(this.ruleForm, field, filteredValue);
    },

    isHasDisease() {
      if (this.ruleForm.sex == null) {
        this.$message.info('请选选择性别')
      }
    },
    async getDiseaseList() {
      let formData = new FormData()
      formData.append('sex', this.ruleForm.sex)
      let res = await api.getDisease(formData)
      if (res.code == 200) {
        console.log(res.data)
        this.diseases = res.data
      }
    },
    getAgeAandSex() {
      let isValidate = isCardID(this.ruleForm.idNumber)
      if (!isValidate) {
        this.ruleForm.age = ''
        this.ruleForm.sex = ''
        this.sexDisabled = false
        return false;
      }
      let dataArr = getIdCard(this.ruleForm.idNumber)
      this.sexDisabled = true
      this.ruleForm.age = dataArr.age
      this.ruleForm.sex = dataArr.sex
    },
    changeIdCardIncludeStart(idNumber) {
      const newStr = idNumber.replace(/^(.{2})(?:\d+)(.{4})$/, "$1****$2");
      return newStr;
    },
    //查询居民详细信息
    async fetchResidentInfo(id) {
      try {
        let res = await api.getResidentInfo(id)
        if (res.code == 200) {
          let form = res.data;
          const bloodFat = JSON.parse(form.bloodFat);
          form.bloodFat = { ...bloodFat };
          if (form.idNumber) {
            this.sexDisabled = true
          }
          form.waistline = form.waistline ? form.waistline : ''
          form.bloodPressure = form.bloodPressure ? form.bloodPressure : ''
          form.bloodSugar = form.bloodSugar ? form.bloodSugar : ''
          this.isIDDisabled = Boolean(form.idNumber);
          this.idNumber = JSON.parse(JSON.stringify(form.idNumber))
          form.idNumber = this.changeIdCardIncludeStart(form.idNumber)
          this.ruleForm = form;
          if (res.data.diseaseId != '') {
            let ids = res.data.diseaseId
            ids = ids.split(',')
            this.diseaseIds = ids.map(Number)
            console.log(this.diseaseIds)
          }
          this.getCommuintyList(res.data.regionCode)
        } else {
          this.$message.error(res.msg)
        }
      } catch (e) {
        this.$message.error(e.msg)
      }
    },

    //获取社区列表
    async getCommityList() {
      try {
        let p = {}
        let res = await api.getCommunitySystemUser(p)
        if (res.code == 200) {
          console.log(res)
        }
      } catch (e) {

      }
    },
    submitForm: debounce(function (formName) {
      if ((this.diseaseIds && this.diseaseIds.length > 0) && this.ruleForm.healthConditionId == 8) {
        let diseaseIds = JSON.stringify(this.diseaseIds)
        diseaseIds = diseaseIds.substr(1)
        diseaseIds = diseaseIds.substring(0, diseaseIds.length - 1)
        this.ruleForm.diseaseId = diseaseIds
        this.ruleForm.otherDisease = ''
      } else if (this.ruleForm.healthConditionId == 9) {
        this.ruleForm.diseaseId = ''
        this.ruleForm.otherDisease = ''
      } else {
        this.ruleForm.diseaseId = ''
      }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let data = {};
          let ruleForm = this.ruleForm;
          if (this.operateBox.operate == 'add') {
            data = {
              idc: ruleForm.idc,
              trueName: ruleForm.trueName,
              age: ruleForm.age,
              sex: ruleForm.sex,
              idNumber: ruleForm.idNumber,
              phoneNumber: ruleForm.phoneNumber,
              diseaseId: ruleForm.diseaseId,
              regionCode: ruleForm.regionCode,
              communityId: ruleForm.communityId,
              healthConditionId: ruleForm.healthConditionId,
              otherDisease: ruleForm.otherDisease,
              height: ruleForm.height,
              weight: ruleForm.weight,
              waistline: ruleForm.waistline,
              bloodFat: JSON.stringify(ruleForm.bloodFat),
              bloodPressure: ruleForm.bloodPressure,
              bloodSugar: ruleForm.bloodSugar
            }
            this.addUser(data)
          } else {
            data = this.ruleForm
            let data = {
              idc: ruleForm.idc,
              id: ruleForm.id,
              trueName: ruleForm.trueName,
              age: ruleForm.age,
              sex: ruleForm.sex,
              idNumber: ruleForm.idNumber.indexOf("*") != -1 ? this.idNumber : ruleForm.idNumber, //传递原始身份证号
              phoneNumber: ruleForm.phoneNumber,
              diseaseId: ruleForm.diseaseId,
              regionCode: ruleForm.regionCode,
              communityId: ruleForm.communityId,
              healthConditionId: ruleForm.healthConditionId,
              otherDisease: ruleForm.otherDisease,
              height: ruleForm.height,
              weight: ruleForm.weight,
              waistline: ruleForm.waistline,
              bloodFat: JSON.stringify(ruleForm.bloodFat),
              bloodPressure: ruleForm.bloodPressure,
              bloodSugar: ruleForm.bloodSugar
            }
            this.updateUser(data)
          }
        } else {
          return false;
        }
      });
    }, 500),
    async addUser(data) {
      try {
        let res = await api.residentAdd(data)
        if (res.code == 200) {
          this.$message.success('新增成功')
          this.$emit('closed')
        } else {
          this.$message.error(res.msg)
        }
      } catch (e) {
        this.$message.error(e.msg)
      }
    },
    async updateUser(data) {
      try {
        let res = await api.residentUpdate(data)
        if (res.code == 200) {
          this.$message.success('更新成功')
          this.$emit('closed')
        } else {
          this.$message.error(res.msg)
        }
      } catch (e) {
        this.$message.error(e.msg)
      }
    },
    cancelFrom(formName) {
      //调用父组件的方法
      this.$emit('closed')
    },

    getAreaCode(val) {
      //const areaCode = this.$refs.myCascader.getCheckedNodes()[0].value
      // this.ruleForm.regionCode=areaCode
      this.ruleForm.communityId = null
      this.getCommuintyList(val)
    },
    async getCommuintyList(code) {
      let data = {
        code: code,
      }
      let page = {
        pageSize: 99999,
        pageNum: 1
      }
      try {
        let res = await api.getCommunitySystemUser(data, page)
        if (res.code == 200) {
          this.communityList = res.rows
        }
      } catch (e) {

      }
    },
  },
  watch: {
    'ruleForm.sex': {
      handler(val) {
        this.getDiseaseList()
      },
      deep: true
    }
  }
}
</script>

<style scoped lang="less">
.el-select {
  width: 100%;
}

.el-cascader {
  width: 100%
}

.height_bxo {
  display: flex;

  div {
    display: flex;
    flex: 1;

    span {
      margin: 0 5px;
    }
  }

  /deep/.el-form-item__content {
    width: 100% !important;
  }
}

.bingzhongIdc {
  /deep/.el-form-item__content {
    width: 80% !important;
  }
}
</style>
