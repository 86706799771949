<template>
    <div class="chart_box" id="line"></div>
</template>

<script>
  import api from '@/api/index'
  import moment from 'moment';
  export default {
        name: "health-condition",
        props:{
          userId:{
            type:Number,
            required:true,
          }
        },
        data() {
            return {
              series:[
                {
                  name: '体重',
                  type: 'line',
                  stack: 'Total',
                  smooth: true,
                  data: []
                },
                {
                  name: '血糖',
                  type: 'line',
                  stack: 'Total',
                  smooth: true,
                  data: []
                }
              ],
              timeArr:[]
            }
        },
      created(){
        let sevenDay =this.getBeforeDate(7)
        let today =moment(new Date()).format('YYYY-MM-DD HH:mm')
        this.getWeight(today,sevenDay)
      },
      mounted(){
        this.getHealthCondition()
      },
      methods:{
          async getWeight(endDate,startDate){
            try {
              let srechArr={
                "endDate":endDate,
                "startDate": startDate,
                "userId":this.userId
              }
              let res =await api.getSevenDayWeight(srechArr)
              if(res.code==200 && res.rows!=''){
               let dataArr=[]
               let timeArr =[]
               res.rows.forEach(item=>{
                 dataArr.push(item.weight)
                 timeArr.push(moment(item.measuringTime).format('MM-DD'))
               })
                this.series[0].data=dataArr
                this.getBloodSuger(srechArr,timeArr)
              }else {

              }
            }catch (e) {
            }
          },
          async getBloodSuger(srechArr,tArr){
            try {
              let res =await api.getSevenDayBloodSuger(srechArr)
              if(res.code==200 && res.rows!=''){
                let dataArr=[]
                let timeArr =[]
                res.rows.forEach(item=>{
                  dataArr.push(item.bloodGlucose)
                  timeArr.push(moment(item.measuringTime).format('MM-DD'))
                })
                this.series[1].data=dataArr
                if(tArr.length >= timeArr.length){
                  this.timeArr=tArr
                }else {
                  this.timeArr=timeArr
                }
                this.getHealthCondition(this.series,this.timeArr)
              }else {
                this.getHealthCondition(this.series,tArr)
              }
            }catch (e) {
              this.getHealthCondition(this.series,tArr)
            }
          },
          getHealthCondition(series,timeArr){
            var myChart = this.$echarts.init(document.getElementById('line'))
            myChart.setOption({
              tooltip: {
                trigger: 'axis'
              },
              legend: {
                data: ['体重', '血糖']
              },
              grid: {
                left: '3%',
                right: '4%',
                bottom: '3%',
                containLabel: true
              },
              toolbox: {
                feature: {
                  saveAsImage: {}
                }
              },
              xAxis: {
                type: 'category',
                boundaryGap: false,
                data:timeArr
              },
              yAxis: {
                type: 'value'
              },
              series: series
            });
          },

          //获取当前日期的前7天
          getBeforeDate(n){
            var n = n;
            var d = new Date();
            var year = d.getFullYear();
            var mon=d.getMonth()+1;
            var day=d.getDate();
            if(day <= n){
              if(mon>1) {
                mon=mon-1;
              }
              else {
                year = year-1;
                mon = 12;
              }
            }
            d.setDate(d.getDate()-n);
            year = d.getFullYear();
            mon=d.getMonth()+1;
            day=d.getDate();

            var  hour = d.getHours(); // 时
            hour = hour < 10 ? '0' + hour : hour; // 如果只有一位，则前面补零
            var  minute = d.getMinutes(); // 分
            minute = minute < 10 ? '0' + minute : minute; // 如果只有一位，则前面补零
            var sevenDay = year+"-"+(mon<10?('0'+mon):mon)+"-"+(day<10?('0'+day):day)+' '+hour+':'+minute;
            return sevenDay;
          },
      },
    }
</script>

<style scoped lang="less">
.chart_box{
  width: 100%;
  height: 500px;
}
</style>
