<template>
  <div class="main_content">
    <div class="title_box">
      <span></span>
      <h5>居民管理</h5>
    </div>
    <div class="line"></div>
    <div class="operate_box">
      <div class="search_box">
        <div class="input_box">
          <span>姓名：</span>
          <el-input v-model="searchForm.trueName" placeholder="请输入姓名" class="set_el_input_style" clearable />
        </div>
        <div class="input_box">
          <span>病种：</span>
          <el-select v-model="searchForm.diseaseId" clearable placeholder="请选择">
            <el-option v-for="item in diseases" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="input_box">
          <span>性别：</span>
          <el-select v-model="searchForm.sex" clearable placeholder="请选择" class="level_box">
            <el-option v-for="item in sex" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="input_box">
          <span>小组：</span>
          <el-select v-model="searchForm.groupId" filterable clearable placeholder="全部">
            <el-option v-for="item in groupList" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </div>

        <div class="input_box">
          <span>赛区：</span>
          <el-input placeholder="请输入赛区" v-model="searchForm.area" class="set_el_input_style" clearable />
        </div>
        <div class="input_box" v-if="this.$store.state.role === 'admin'">
          <span>地区：</span>
          <el-select v-model="searchForm.regionCode" filterable @change="getAreaCode" @focus="getArea" clearable>
            <el-option v-for="item in areaData" :key="item.regionCode" :value="item.regionCode" :label="item.area" />
          </el-select>
        </div>
        <div class="input_box" v-if="this.$store.state.role === 'admin'">
          <span>社区：</span>
          <el-select v-model="communityId" filterable clearable placeholder="请选择">
            <el-option v-for="item in communityList" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </div>
        <div class="input_box"><el-button type="primary" class="el-icon-search" @click="searchTable"></el-button></div>
      </div>
      <div class="operate_btn">
        <el-button type="primary" icon="el-icon-plus" @click="addUser">新增</el-button>
        <el-button type="primary" icon="el-icon-plus" @click="joinGroup">加入小组</el-button>
        <el-button type="warning" icon="el-icon-download" @click="idNumberMode">身份证导入模板</el-button>
        <el-upload class="upload-demo" action="uploadUrl"
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          :http-request="idNumberUpload">
          <el-button type="warning" icon="el-icon-upload2">身份证号导入</el-button>
        </el-upload>

        <el-button type="danger" icon="el-icon-delete" @click="deleteTest">批量删除</el-button>

      </div>
    </div>
    <div class="tbale_box">
      <el-table v-loading="loading" :data="tableData" border style="width: 100%"
        @selection-change="handleSelectionChange" height="100%">
        <el-table-column type="selection" width="55" />
        <el-table-column prop="id" label="ID" width="100" />
        <el-table-column prop="trueName" label="姓名" width="100" />
        <el-table-column prop="weixinName" label="微信昵称" width="135" />
        <el-table-column prop="phoneNumber" label="电话" width="140" />
        <el-table-column prop="age" label="年龄(岁)" width="70" />
        <el-table-column prop="sex" label="性别" width="50">
          <template slot-scope="scope">
            <span v-if="scope.row.sex == 1">男</span>
            <span v-else>女</span>
          </template>
        </el-table-column>
        <el-table-column prop="groupName" label="小组" width="120" />
        <el-table-column prop="area" label="赛区" width="100" />
        <el-table-column prop="communityName" label="社区" />
        <el-table-column prop="regionCode" label="地区" />
        <el-table-column prop="createdDatetime" label="注册时间" width="135" />
        <el-table-column width="140" label="操作">
          <template slot-scope="scope">
            <el-tooltip class="item" effect="dark" content="编辑" placement="top">
              <el-button type="primary" icon="el-icon-edit" circle @click="editUser(scope.row)"></el-button>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" content="删除" placement="top">
              <el-button type="danger" icon="el-icon-delete" circle @click="deleteUser(scope.row)"></el-button>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" content="自身情况曲线" placement="top">
              <el-button type="warning" icon="el-icon-s-data" circle @click="getSelfCondition(scope.row)"></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pageBox.pageNum"
      :page-sizes="[10, 20, 50, 100, 200, 300, 400]" :page-size="pageBox.pageSize"
      layout="total, sizes, prev, pager, next, jumper" :total="pageBox.total" />

    <!-- <new-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :page-size=pageBox.pageSize
      layout="total,sizes, prev, pager, next" :total=pageBox.total>
    </new-pagination> -->

    <!--新增编辑居民-->
    <el-dialog :visible.sync="editDialog" width="40%" :close-on-click-modal="false" :close-on-press-escape="false"
      class="set_dialog_style">
      <div slot="title" class="dialog_title">
        <span></span>{{ operateBox.title }}
      </div>
      <edit-from :operateBox="operateBox" :key="timer" @closed="closed"></edit-from>
    </el-dialog>

    <!--加入小组-->
    <el-dialog :visible.sync="joinGroupDialog" width="50%" :close-on-click-modal="false" :close-on-press-escape="false"
      class="set_dialog_style">
      <div slot="title" class="dialog_title">
        <span></span>{{ operateBox.title }}
      </div>
      <join-group @closed="closed" :groupUserIds="groupUserIds" :oldGroupId="oldGroupId" :key="timer"></join-group>
    </el-dialog>

    <!--自身情况曲线弹窗-->
    <el-dialog :visible.sync="healthConditionDialog" width="50%" :close-on-click-modal="false"
      :close-on-press-escape="false" class="set_dialog_style">
      <div slot="title" class="dialog_title">
        <span></span>自身情况变化趋势
      </div>
      <health-condition @close="closed" :userId="userId" :key="timer"></health-condition>
    </el-dialog>
  </div>
</template>

<script>
import api from '@/api/index'
import EditFrom from './components/edit-from'
import JoinGroup from './components/join-group'
import { diseaseData } from '@/utils/baseData'
import { chinaArea } from '@/utils/china-area-data'
import HealthCondition from "./components/health-condition";
export default {
  name: 'index',
  components: { HealthCondition, JoinGroup, EditFrom },
  data() {
    return {
      diseases: diseaseData,
      sex: [
        {
          value: 1,
          label: '男'
        },
        {
          value: 2,
          label: '女'
        }],
      groupList: [],
      area: [],
      communityList: [],
      areaData: [],
      tableData: [],
      editDialog: false,
      operateBox: {
        title: '新增',
        operate: 'add',
        id: null
      },
      joinGroupDialog: false,
      searchForm: {
        trueName: "",
        diseaseId: '',
        sex: '',
        groupId: '',
        communityId: null,
        communityIds: null,
        regionCode: '',
        area: ''
      },
      communityId: null,
      pageBox: {
        pageSize: 10,
        pageNum: 1,
        total: 0
      },
      groupUserIds: [],
      oldGroupId: [],
      delUserIds: null,
      timer: null,
      healthConditionDialog: false,
      userId: '',
      loading: false
    }
  },
  created() {
    this.fetchUserPower()
    // this.getResidents(this.searchForm,this.pageBox)
  },
  mounted() {

  },
  methods: {
    //根据UserID回显权限res,拿到医生权限管理的社区
    async fetchUserPower() {
      try {
        console.log(this.$store.state.userId);
        let res = await api.getUserPower(this.$store.state.userId)
        console.log(res);
        if (res.code == 200) {
          if (this.$store.state.role !== 'admin') {
            this.searchForm.communityIds = res.data.comId
          }
          this.getResidents(this.searchForm, this.pageBox)
          this.getGroupList()
        }
      } catch (e) {

      }
    },
    //获取小组列表
    async getGroupList() {
      let pageBox = {
        pageSize: 99999,
        pageNum: 1
      }
      let data = {
        regionCode: null,
        communityIds: this.searchForm.communityIds
      }
      let res = await api.getGroupListes(data, pageBox)
      if (res.code == 200) {
        this.groupList = res.rows
      }
    },
    //获取居民列表
    async getResidents(data, page) {
      this.loading = true
      try {
        let res = await api.getResidentList(data, page)
        if (res.code == 200) {
          this.tableData = res.rows
          this.pageBox.total = res.total
        } else {
          this.$message.error(res.msg)
        }
      } catch (e) {
        this.$message.error(e.msg)
      } finally {
        this.loading = false
      }
    },
    /* 一页显示多少条切换 */
    handleSizeChange(val) {
      this.pageBox.pageSize = val
      this.getResidents(this.searchForm, this.pageBox)
    },
    /* 分页切换 */
    handleCurrentChange(val) {
      this.pageBox.pageNum = val
      if (this.pageBox.pageNum * this.pageBox.pageSize > this.pageBox.total) {
        let product = {
          pageSize: this.pageBox.pageSize,
          pageNum: this.pageBox.pageNum,
          total: this.pageBox.total
        }
        //product.pageSize = this.pageBox.total - this.pageBox.pageSize
        this.getResidents(this.searchForm, product)
      } else {
        this.getResidents(this.searchForm, this.pageBox)
      }
      this.getResidents(this.searchForm, this.pageBox)
    },
    //根据条件查询
    searchTable() {
      this.pageBox.pageNum = 1
      this.getResidents(this.searchForm, this.pageBox)
    },
    // 新增居民
    addUser() {
      this.operateBox = {
        title: '添加居民',
        operate: 'add',
        id: null
      }
      this.timer = new Date().getTime()
      this.editDialog = true
    },
    //更新居民
    editUser(row) {
      this.operateBox = {
        title: '编辑用户',
        operate: 'edit',
        id: row.id
      }
      this.timer = new Date().getTime()
      this.editDialog = true
    },
    // 加入小组
    joinGroup() {
      if (this.groupUserIds == '') {
        this.$message.warning('请选择至少一位居民')
      } else {
        this.joinGroupDialog = true
        this.operateBox.title = "加入小组"
      }

    },

    //选中要删除的居民获取对应的id
    handleSelectionChange(val) {
      let ids = []
      let oldGroupId = []
      val.forEach(item => {
        ids.push(item.id);
        oldGroupId.push(item.groupId)
      })
      this.groupUserIds = ids
      this.oldGroupId = oldGroupId
      this.delUserIds = { ids: ids }
    },
    //批量删除按钮
    deleteTest() {
      if (this.delUserIds == null) {
        this.$message.info('请至少选中一位居民数据进行删除操作')
        return false
      }
      this.$confirm('删除用户会影响小组积分和排名，确定删除该数据吗?', '信息', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.delUserInfo(this.delUserIds)
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    deleteUser(row) {
      this.$confirm('删除用户会影响小组积分和排名，确定删除该数据吗?', '信息', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.delUserInfo({ ids: [row.id] })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },

    //删除或批量删除居民
    async delUserInfo(ids) {
      try {
        let res = await api.delResident(ids)
        if (res.code == 200) {
          this.getResidents(this.searchForm, this.pageBox)
        }
      } catch (e) {
        this.$message.error(e.msg)
      }
    },
    getArea() {
      this.areaData = this.$store.state.userArea
    },
    async getAreaCode(val) {
      this.communityId = null
      this.searchForm.communityId = null
      let data = {
        code: val,
      }
      let pageBox = {
        pageNum: 1,
        pageSize: 99999
      }
      let res = await api.getCommunitySystemUser(data, pageBox)
      if (res.code == 200 && res.rows) {
        this.communityList = res.rows
      } else {
        this.communityList = []
      }
    },
    //关闭弹窗
    closed(val) {
      console.log(val)//接受子组件的传值
      this.editDialog = false
      this.joinGroupDialog = false
      this.healthConditionDialog = false
      this.getResidents(this.searchForm, this.pageBox)
    },
    //身份证模板导出
    async idNumberMode() {
      try {
        let res = await api.idNumberDlownd()
        let name = '身份证导入模板'
        let blob = new Blob([res], { type: "xls" });
        let downloadElement = document.createElement("a");
        let href = window.URL.createObjectURL(blob); //创建下载的链接
        downloadElement.href = href;
        downloadElement.download = `${name}.xls`; //下载后文件名
        document.body.appendChild(downloadElement);
        downloadElement.click(); //点击下载
        document.body.removeChild(downloadElement); //下载完成移除元素
        window.URL.revokeObjectURL(href); //释放掉blob对象
      } catch (e) {

      }
    },

    //身份证号导入
    async idNumberUpload(prams) {
      console.log(prams.file)
      try {
        let formData = new FormData();
        formData.append("file", prams.file);
        let res = await api.importIdNumber(formData)
        if (res.code == 200) {
          this.$message.seccess('导入成功！')
          console.log(res)
          this.getResidents(this.searchForm, this.pageBox)
        } else {
          this.$message.warning(res.msg)
        }
      } catch (e) {
        this.$message.warning('导入失败！')
      }
    },
    //获取自身情况曲线
    getSelfCondition(row) {
      this.timer = new Date().getTime()
      this.healthConditionDialog = true
      this.userId = row.id
    }
  }
}
</script>

<style scoped lang="less">
.el-pagination {
  text-align: center;
}

.set_el_input_style {
  width: 120px
}

.search_box {
  width: 55% !important;
  flex-wrap: wrap;
  height: 90px;
}

.operate_btn {
  width: 45%;
  text-align: right;
  display: flex;
  justify-content: right;

  /deep/.upload-demo {
    margin: 0 10px
  }

  /deep/.el-upload-list {
    display: none;
  }
}

.tbale_box {
  height: calc(100% - 193px);
}
</style>
