<template>
  <div class="add_group_box">
    <div class="operate_box">
      <div class="search_box" v-if="this.$store.state.role === 'admin'">
        <div class="input_box">
          <span>地区：</span>
          <el-select v-model="searchForm.regionCode" filterable @focus="getArea" clearable>
            <el-option v-for="item in options" :key="item.regionCode" :value="item.regionCode" :label="item.area" />
          </el-select>
        </div>
        <div class="input_box"><el-button type="primary" class="el-icon-search" @click="searchTable"></el-button></div>
      </div>
      <div class="operate_btn">
        <el-button type="primary" icon="el-icon-check" @click="inGroup">确认</el-button>
<!--        <el-button type="primary" icon="el-icon-download">导出</el-button>
        <el-button type="primary" icon="el-icon-printer">打印</el-button>-->
      </div>
    </div>
    <div class="table_box">
      <el-table v-loading="loading" :data="tableData" border style="width:100%" height="100%">
        <el-table-column label="ID" width="100">
          <template slot-scope="scope">
            <el-radio :label="scope.row.id" v-model="redioValue"></el-radio>
          </template>
        </el-table-column>
        <el-table-column prop="name" label="小组名称" />
        <el-table-column prop="regionCode" label="地区" />
        <el-table-column prop="num" label="当前人数" />
      </el-table>
    </div>

    <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pageBox.pageNum"
      :page-sizes="[10, 20, 50, 100, 200, 300, 400]" :page-size="pageBox.pageSize"
      layout="total, sizes, prev, pager, next, jumper" :total="pageBox.total" />
  </div>
</template>

<script>
import api from '@/api/index'
export default {
  props: {
    groupUserIds: {
      type: Array,
      required: true
    },
    oldGroupId: {
      type: Array,
      required: true
    }
  },
  name: "join-group",
  data() {
    return {
      loading: false,
      tableData: [],
      searchForm: {
        regionCode: null,
        communityIds: null
      },
      pageBox: {
        pageSize: 10,
        pageNum: 1,
        total: 0
      },
      redioValue: null,
      options: null
    }
  },
  created() {
    this.fetchUserPower()
  },
  methods: {
    //根据地区查询社区
    getArea() {
      this.options = this.$store.state.userArea
    },
    searchTable() {
      this.getGroupList(this.searchForm, this.pageBox)
    },
    //根据UserID回显权限res,拿到医生权限管理的社区
    async fetchUserPower() {
      try {
        let res = await api.getUserPower(this.$store.state.userId)
        if (res.code == 200) {
          let data = {}
          if (this.$store.state.role !== 'admin') {
            data.communityIds = res.data.comId
          }
          this.searchForm = data
          this.getGroupList(data, this.pageBox)
        }
      } catch (e) {

      }
    },

    //获取小组列表
    async getGroupList(data, pageBox) {
      this.loading = true
      try {
        let res = await api.getGroupListes(data, pageBox)
        console.log(res)
        res.rows.forEach(item => {
          item.auth = JSON.parse(item.auth)
        })
        this.tableData = res.rows
        this.pageBox.total = res.total

      } catch (e) {
        this.$message.error(e.msg)
      } finally {
        this.loading = false
      }
    },
    /* 一页显示多少条切换 */
    handleSizeChange(val) {
      this.pageBox.pageSize = val
      this.getGroupList(this.searchForm, this.pageBox)
    },
    /* 分页切换 */
    handleCurrentChange(val) {
      this.pageBox.pageNum = val
      this.getGroupList(this.searchForm, this.pageBox)
    },
    //加入小组
    async inGroup() {
      try {
        if (this.redioValue == null) {
          this.$message.warning('请选择要加入的小组')
        } else {
          let data = {
            "groupId": this.redioValue,
            "oldGroupId": this.oldGroupId,
            "ids": this.groupUserIds
          }
          let res = await api.intoGroup(data)
          if (res.code == 200) {
            this.$message.success('加入小组成功')
            this.$emit('closed')
            // 加入小组成功后手动触发获取小组列表数据
            this.getGroupList(this.searchForm, this.pageBox)
          } else {
            this.$message.error(res.msg)
          }
        }
      } catch (error) {
        this.$message.error(error.msg)
      }
    }
  }
}
</script>

<style scoped lang="less">
.el-pagination {
  text-align: center;
}

.add_group_box {
  height: 600px;

  .operate_box {
    height: 36px;
  }

  .table_box {
    height: calc(100% - 60px);
    margin-bottom: 10px;
  }
}
</style>
